<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-12">
                <h2 class="fw-400 font-lg d-block"><b>Member of Group - {{data.name}}</b></h2>
            </div>
            <div class="col-12 mb-3">
                <div id="accordion" class="accordion mb-0">
                    <div class="card shadow-xss mb-0">
                        <div class="card-header bg-greylight theme-dark-bg" id="heading">
                            <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapse">Add new Member</button></h5>
                        </div>
                        <div id="collapse" class="collapse" aria-labelledby="heading" data-parent="#accordion">
                            <div class="card-body">
                                <form @submit.prevent="postMember()" id="form-add" class="row">
                                    <div class="form-group col-lg-10">
                                        <select v-model="form.user_id" class="form-control" required>
                                            <option value="" selected disabled>Choose Student</option>
                                            <option v-for="(item, index) in unmember" :key="index" :value="item.user.id">{{item.user.name}} - {{item.user.email}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <input type="submit" class="btn btn-lg btn-block btn-current" value="Submit!" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
            </div>
            <div class="card-body p-0">
                <div v-if="!isLoad" class="col-12 text-center py-3">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data.member" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ row.user.name }}</td>
                                    <td>{{ row.user.email }}</td>
                                    <td>{{ row.user.username }}</td>
                                    <td>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            idGroup: this.$route.params.idGroup,
            isLoad: true,
            form: {
                user_id: ''
            },
            data: {},
            unmember: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Email', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Username', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Action', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
            ]
        }
    },
    created(){
        this.getMember()
    },
    methods:{
        async getMember(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/group/member?slug=${this.paramsId}&group_id=${this.idGroup}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                return this.data
            }).then(async(data) => {
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/everyone/not-member?slug=${this.paramsId}&cycle=${data.cycle}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.unmember = res.data.data
                    this.isLoad = false
                })
            })
        },
        async postMember() {
            var data = {
                group_id: this.idGroup,
                user_id: this.form.user_id
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/people/group/member`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Group Member!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.form.user_id = ''
                this.getMember()
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Group !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        group_id: this.idGroup,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/people/group/member`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Group Member!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getMember()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Group Member!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
    watch: {
        cycle(){
            this.getMember()
        }
    }
}
</script>